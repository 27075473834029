import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  noIndexing: boolean;
}

const Seo = ({ title, noIndexing = false }: Props) => {
  const description = 'HEURECA est un produit destiné à tous les commerçants qui désirent proposer à leurs clients une plateforme de commande pour les ventes à l\'emporter.';

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <link rel="icon" sizes="16x16 32x32 64x64" href="/favicons/favicon.ico" />
        <link rel="icon" type="image/png" sizes="196x196" href="/favicons/favicon-192.png" />
        <link rel="icon" type="image/png" sizes="160x160" href="/favicons/favicon-160.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicons/favicon-96.png" />
        <link rel="icon" type="image/png" sizes="64x64" href="/favicons/favicon-64.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16.png" />
        <link rel="apple-touch-icon" href="/favicons/favicon-57.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/favicons/favicon-114.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/favicons/favicon-72.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/favicons/favicon-144.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/favicons/favicon-60.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/favicons/favicon-120.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/favicons/favicon-76.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/favicons/favicon-152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/favicon-180.png" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta name="msapplication-TileImage" content="/favicons/favicon-144.png" />
        <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
        {noIndexing && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
      <Helmet
        htmlAttributes={{ lang: 'fr' }}
        title={title}
        titleTemplate="%s | Heureca"
        meta={[
          {
            name: 'description',
            content: description,
          },
          {
            name: 'keywords',
            content: 'heureca, vendre, vente en ligne, commerçants, market, ingenum',
          },
          {
            property: 'og:title',
            content: 'Heureca',
          },
          {
            property: 'og:description',
            content: description,
          },
          {
            property: 'og:type',
            content: 'website',
          },
          {
            property: 'og:image',
            content: '/og-image.jpg',
          },
          {
            name: 'twitter:card',
            content: 'summary',
          },
          {
            name: 'twitter:creator',
            content: 'Ingenum',
          },
          {
            name: 'twitter:title',
            content: 'Heureca',
          },
          {
            name: 'twitter:description',
            content: description,
          },
        ]}
      />
    </>
  );
};

export default Seo;
