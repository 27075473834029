// extracted by mini-css-extract-plugin
export var header = "header-module--header--gl2B5";
export var container = "header-module--container--1MffE";
export var signUp = "header-module--signUp--RoS-Z";
export var scrolled = "header-module--scrolled--Rk0ja";
export var logo = "header-module--logo--UF7sR";
export var content = "header-module--content--2g74C";
export var anchors = "header-module--anchors--xUeBE";
export var navLink = "header-module--navLink--2rDEY";
export var open = "header-module--open--3J7c8";
export var menuToggle = "header-module--menuToggle--1lOTw";
export var burger = "header-module--burger--uEd01";