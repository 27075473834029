/* eslint-disable react/button-has-type */
import React from 'react';
import classnames from 'classnames';

import * as styles from './linkButton.module.scss';

interface Props {
  label: string;
  link: string;
  isExternal?: boolean;
  rel?: string;
  classNames?: string;
}

const LinkButton = (props: Props) => {
  const {
    label,
    classNames,
    link,
    isExternal,
    rel,
  } = props;

  const classes = classnames(styles.button, classNames);

  const target = isExternal ? '_blank' : '_self';

  return (
    <a
      href={link}
      className={classes}
      target={target}
      rel={rel}
    >
      {label}
    </a>
  );
};

export default LinkButton;
