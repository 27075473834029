/* eslint-disable react/button-has-type */
import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';

import * as styles from './button.module.scss';

interface Props {
  label?: string;
  classNames?: string;
  role?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'submit' | 'button';
}

const Button = (props: PropsWithChildren<Props>) => {
  const {
    label,
    classNames,
    role = 'button',
    onClick,
    children,
    disabled = false,
    type = "button",
  } = props;

  const classes = classnames(styles.button, classNames, { [styles.disabled]: disabled });

  return (
    <button
      disabled={disabled}
      className={classes}
      type={type}
      role={role}
      onClick={onClick}
    >
      {label}
      {children}
    </button>
  );
};

export default Button;
