import { useEffect, useState } from 'react';
import useDebounce from './useDebounce';

const MIN_SCROLL = 35;

const useScroll = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const debouncedSetIsScrolled = useDebounce((scrolled: boolean) => setIsScrolled(scrolled), 15);

  useEffect(() => {
    const scrollHandler = () => debouncedSetIsScrolled(window.scrollY >= MIN_SCROLL);

    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return {
    isScrolled,
  };
};

export default useScroll;
