import { useEffect, useState } from 'react';
import useDebounce from './useDebounce';

interface WindowSize {
  width: number;
  height: number;
}

const TABLET_MIN_WIDTH = 768;
const DESKTOP_MIN_WIDTH = 1024;
const LARGE_MIN_WIDTH = 1200;

const useWindowSize = (debounceDelay = 150) => {
  const [windowSize, setWindowSize] = useState<WindowSize>({ width: 0, height: 0 });
  const debouncedSetWindowSize = useDebounce(
    (values: WindowSize) => setWindowSize(values),
    debounceDelay,
  );
  const { width } = windowSize;

  useEffect(() => {
    const resizeHandler = () =>
      debouncedSetWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const isMobile = width < TABLET_MIN_WIDTH;
  const isTablet = width >= TABLET_MIN_WIDTH && width < DESKTOP_MIN_WIDTH;
  const isDesktop = width >= DESKTOP_MIN_WIDTH && width < LARGE_MIN_WIDTH;
  const isLarge = width >= LARGE_MIN_WIDTH;

  return {
    windowWidth: windowSize.width,
    windowHeight: windowSize.height,
    isMobile,
    isTablet,
    isDesktop,
    isLarge,
  };
};

export default useWindowSize;
