import React, { PropsWithChildren } from 'react';

import Seo from '@heureca/shared/components/Seo';
import Header from '@heureca/header/components/Header';
import Footer from '@heureca/footer/components/Footer';

import '@styles/main.scss';

interface Props {
  pageTitle: string;
  isSignUp?: boolean;
  noIndexing?: boolean;
}

const Layout = ({
  pageTitle,
  children,
  isSignUp = false,
  noIndexing,
}: PropsWithChildren<Props>) => (
  <>
    <Seo title={pageTitle} noIndexing={noIndexing} />
    {!noIndexing && <Header isSignUp={isSignUp} />}
    <main>{children}</main>
    <Footer />
  </>
);

export default Layout;
