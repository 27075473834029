import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import useWindowSize from '@heureca/shared/hooks/useWindowSize';
import useScroll from '@heureca/shared/hooks/useScroll';
import Button from '@heureca/shared/components/Button';
import HeurecaLogo from '@assets/images/heurecaLogo.svg';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import LinkButton from '@heureca/shared/components/LinkButton';
import sections from '@data/sections.data.json';

import Section from '../models/Section.model';

import * as styles from './header.module.scss';

interface Props {
  isSignUp: boolean;
}

const Header = ({ isSignUp }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const { isScrolled } = useScroll();
  const { translate } = useTranslations();

  const headerClasses = classnames(styles.header, { [styles.scrolled]: isScrolled });
  const anchorsClasses = classnames(styles.anchors, { [styles.open]: isMenuOpen });

  useEffect(() => {
    if (!isMobile && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMobile]);

  return (
    <header className={headerClasses}>
      <div className={isSignUp ? styles.signUp : styles.container}>
        <a href="/">
          <img src={HeurecaLogo} alt="" className={styles.logo} />
        </a>
        {!isSignUp && (
          <div className={styles.content}>
            <ul className={anchorsClasses}>
              {sections.map((s: Section) => (
                <li key={s.id} className={styles.navLink}>
                  <a href={`#${s.id}`} onClick={isMobile ? () => setIsMenuOpen(false) : null}>
                    {translate(`sections.${s.id}`)}
                  </a>
                </li>
              ))}
            </ul>
            <Button label="" classNames={styles.menuToggle} onClick={() => setIsMenuOpen((s) => !s)}>
              <span className={styles.burger} />
            </Button>
            <LinkButton
              label={translate('sections.start')}
              link={process.env.GATSBY_FUNNEL_URL}
            />
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
