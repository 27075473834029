import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import useTranslations from '@heureca/shared/hooks/useTranslations.hook';

import * as styles from './footer.module.scss';

const Footer = () => {
  const { translate } = useTranslations();

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.socials}>
          <a href="https://www.facebook.com/heureca.eu" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebookSquare} className={styles.icon} />
          </a>
          <a href="https://www.instagram.com/heureca.eu" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagramSquare} className={styles.icon} />
          </a>
          <a href="https://www.linkedin.com/company/heureca" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedin} className={styles.icon} />
          </a>
        </div>
        <p className={styles.paragraph}>
          {translate('footer.madeBy')}
          <a href="https://ingenum.be" className={styles.link}>
            Ingenum
          </a>
        </p>
        <p className={styles.paragraph}>
          {translate('footer.allRightReserved')}
          {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
